@import '../../partials/global';

.aboutme {
    height: 130vw;
    background-color: $background;
    margin: 0;

    &__flowers {
        width: 35%;
        top: 1;
        margin-top: 141.5vw;
        position: absolute;
        right: 0;
        transform: scaleX(-1);
        z-index: 998;
    }

    &__heading {
        // background-image: url('../../assets/images/aot2.gif');
        background-position: 20% 30%;
        background-clip: text;
        color: white;
        padding: 15vw 0 0 8vw;
        margin: 0;
        text-align: left;
        text-shadow: -0.03vw -0.03vw 0 #000, 0.03vw -0.03vw 0 #000, -0.03vw 0.03vw 0 #000, 0.03vw 0.03vw 0 #000;
    }
    &__image {
        position: absolute;

        &1 {
            width: 50vw;
            height: 21vw;
            left: 0;
            top: 1;
            margin-top: 31vw;
        }
        &2 {
            width: 40vw;
            height: 16vw;
            right: 10%;
            top: 1;
            margin-top: 106vw;
        }

    }
    &__section {
        position: absolute;
        display: flex;

        &-title {      

            &2 {
                font-weight: normal;
                width: 25%;
                position: absolute;   
                top: 1;
                padding-top: 1.5vw;     
                font-family: 'Antically';   
                vertical-align: middle;  
                line-height: 5vw;
                border-radius: 0.25vw;
                font-size: 3vw;
                z-index: 997;
                border: none;
                opacity: 0.8;
                margin-top: 13.5vw;
                left: 44vw;
                font-size: 2vw;
                color: white;
            }     
        }
        &-box {
            opacity: 0.3;

            &1 {
                background-image: url('../../assets/images/strangerthings.gif'); 
                background-position: 100% 0%; 
            }
            &2 {
                background-image: url('../../assets/images/spiritedaway.gif'); 
                background-position: 10% 30%;
            }
        }
        &-polaroid {
            position: absolute;

            &1 {
                width: 20vw;
                right: 28vw;
                transform: rotate(-10deg);
                margin-top: -17vw;
                height: 30vw;
            }
            &2 {
                width: 25vw;
                right: 8vw;
                margin-top: 0vw;
                transform: rotate(20deg);
                z-index: 997;
            }
            &3 {
                width: 18vw;
                right: 25vw;
                margin-top: 16vw;
                transform: rotate(10deg);
            }
            &4 {
                width: 15vw;
                right: 5vw;
                margin-top: -16vw;
                transform: rotate(10deg);
                z-index: 996;
            }
            &5 {
                width: 12.5vw;
                height: 18.5vw;
                right: 4.45vw;
                margin-top: 18vw;
                transform: rotate(-16deg);
                z-index:998;
            }
            &6 {
                width: 15vw;
                right: 80vw;
                transform: rotate(-10deg);
                margin-top: 75vw;
                z-index: 998;
            }
            &7 {
                width: 25vw;
                right: 46vw;
                margin-top: 76vw;
                transform: rotate(-2deg);
                z-index: 998;
            }
            &8 {
                width: 21vw;
                height: 21.5vw;
                right: 65vw;
                margin-top: 85vw;
                transform: rotate(10deg);
                z-index: 997;
            }
        }
        &-photo {
            position: absolute;
            
            &1 {
                width: 18vw;
                right: 29.5vw;
                margin-top: -15vw;
                transform: rotate(-10deg);
            }
            &2 {
                width: 24.25vw;
                left: 66.5vw;
                transform: rotate(20deg);
                margin-top: 1.5vw;
                z-index: 997;
            }
            &3 {
                width: 16.25vw;
                right: 25.75vw;
                margin-top: 17.5vw;
                transform: rotate(10deg);
            }
            &4 {
                width: 13.5vw;
                right: 5.5vw;
                margin-top: -15vw;
                transform: rotate(10deg);
            }
            &5 {
                width: 13.5vw;
                height: 13vw;
                right: 4.25vw;
                margin-top: 19.75vw;
                transform: rotate(-16deg);
                z-index: 998;
            }
            &6 {
                width: 13.25vw;
                right: 81vw;
                margin-top: 76vw;
                transform: rotate(-10deg);
                z-index: 998;
            }
            &7 {
                width: 20vw;
                right: 65vw;
                margin-top: 86.5vw;
                transform: rotate(10deg);
                z-index: 997;
            }
            &8 {
                width: 24vw;
                right: 46.5vw;
                margin-top: 77.5vw;
                transform: rotate(-2deg);
                z-index: 998;
            }
        }
    }
    &__text {
        width: 35%;
        margin-left: 8vw;
        text-align: left;
        font-weight: bolder;

        &2 {
            margin-top: 80vw;
            width: 39%;
            font-weight: bold;
            z-index: 997;
            margin-left: 12vw;
        }
    }
    &--bold {
        font-family:'GraphikMedium';
    }
}

.valorant {
    font-family: 'Valorant';
    color: $orangered;

    &:hover {
        color: rgba($orangered, 0.6);
    }
}

.league {
    color: rgb(94, 124, 94);

    &:hover {
        color: rgba(115, 153, 115, 0.6);
    }
}

.benji {
    color: #9e8e78;
    font-weight: bold;

    &:hover {
        color: rgba(#9e8e78, 0.6)
    }
}

.outline {
    font-family: 'College Halo';
    font-size: 6.5vw;
    font-weight: normal;
}

.brighter {
    opacity: 0.6;
    font-weight: bold;
}

.container {
    position: relative;
}
.blurred {
    opacity: 0;
    position: fixed;
}