@import '../../partials/global';

.contactbutton {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 5.5vw 0 17vw 0;

    &__polaroid {
        position: absolute;
        width: 20.825vw;
        height: 19vw;
    }
}