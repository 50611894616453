@import '../../partials/global';

.projects {
    height: 75vw;
    background-color: $background;
    padding-bottom: 5vw;
    
    &__section {
    
        &-box {
        background-image: url('../../assets/images/sorafull.gif');
        background-position: 80% 55%;
        opacity: 0.4;
        }
    }

    &__heading {
        // background-image: url('../../assets/images/appa2a.gif');
        background-size: 43%;
        background-position: 37.75% 60%;
        background-clip: text;
        color: white;
        text-shadow: -0.03vw -0.03vw 0 #000, 0.03vw -0.03vw 0 #000, -0.03vw 0.03vw 0 #000, 0.03vw 0.03vw 0 #000;
        padding-top: 13vw;
        margin: 0;
    }

    &__flowers {
        width: 35%;
        top: 1;
        margin-top: 73vw;
        position: absolute;
        left: 0;
        z-index: 998;  
    }
    &__display {
        display: flex;
        margin-top: 5vw;
        justify-content: center;
        width: 100%;
        // padding: 5vw 0;

        &--main {
            width: 50%;
            position: relative;

            &-polaroid {
                width: 45vw;
                height: 38.5vw;
                position: absolute;
                left: 0;
                margin-left: 2vw;
                z-index: 998;
            }
        }
        &-hero {
            top: 1;
            margin-top: 3vw;
            height: 28.5vw;
            width: 41.75vw;
            margin-left: -0.3vw;
            
            z-index: 996;
        }
        &-name {
            position: absolute;
            top: 0;
            margin-top: 34vw;
            left: 1vw;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            z-index: 998;
            font-size: 2.5vw;
            font-family: 'Kintanya';
        }
        &-description {
            position: absolute;
            display: flex;
            z-index: 998;
            font-size: 0.9vw;
            width: 15vw;
            line-height: 1.2vw;
            top: 1;
            margin-top: -26.5vw;
            right: -19vw;
            font-weight: bold;
            font-family: 'Graphik';
            text-align: left;
            height: 5.5vw;
        }
        &-date {
            position: absolute;
            font-family: 'Handwriting';
            font-size: 1.5vw;
            top: 1;
            margin-top: -30.25vw;
            right: -8vw;
            z-index: 998;
        }
        &-link {
            font-size: 2.5vw;
            color: black;
        }
    }
    &__paper {
        top: 1;
        width: 20vw;
        height: 25vw;
        margin-top: 0.5vw;
        margin-right: 1.5vw;
        right: 0;
        left: 0;
        z-index: 998;
    }
    &__postit {
        position: absolute;
        width: 15vw;
        left: 0;
        margin-left: 53vw; 
        filter: saturate(50%);
        top: 1;
        margin-top: 23vw;
        transform: rotate(345deg);
        z-index: 998;

        &-title {
            position: absolute;
            font-family: 'Kintanya';
            font-weight: bold;
            font-size: 1.3vw;
            top: 1;
            margin-top: 25.25vw;
            transform: rotate(343deg);
            z-index: 998;
            right: -11vw;
            text-decoration: $orangered underline;
        }
    }
    &__more {
        width: 1.5vw;
        height: 3.5vw;
        opacity: 0.5;
        position: absolute;
        right: 4vw;
        z-index: 998;
        top: 1;
        margin-top: 37vw;

        &:hover {
            cursor: pointer;
            opacity: 1;
        }
        &-container {
            
            &:hover {
                animation: loadDots 3s ease;
            }        
        }
    }
}