@import '../../partials/global';

.projectqueue {
    display: flex;
    justify-content: center;
    margin-top: -1vw;
    flex-wrap: wrap;
    height: 41vw;
    overflow: hidden;
    overflow-y: scroll;
    width: 20%;
    border-left: 0.1vw solid white;

    &::-webkit-scrollbar {
        display: none;
    }

    &__heading {
        font-size: 1.35vw;
        font-family: 'Antically';
        color: $fadedorange;
        text-decoration: underline;
        font-weight: bold;
        margin: 0;
        padding: 0;
    }

    &__polaroid {
        width: 15vw;
        height: 12.75vw;
        position: relative;
        z-index: 998;
    }

    &__container {
        &:hover {
            cursor: pointer;
            filter: brightness(70%);
        }
    }

    &__content {
        position: relative;

        &-thumbnail {
            width: 13.1vw;
            height: 8.7vw;
            position: absolute;
            top: 1;
            margin-top: -11.75vw;
            right: 1vw;
            z-index: 996;
            
        }

        &-name {
            position: absolute;
            top:1;
            margin: -2.6vw auto 0 auto;
            z-index: 998;
            left: 0; 
            right: 0; 
            font-family: 'Kintanya';
            font-size: 1.25vw;
        }
    }
}