@font-face {
    font-family: 'Graphik';
    src: url('../assets/fonts/GraphikRegular.otf') format('OpenType');
}
@font-face {
    font-family: 'GraphikMedium';
    src: url('../assets/fonts/GraphikMedium.otf') format('OpenType');
}
@font-face {
    font-family: 'GraphikBold';
    src: url('../assets/fonts/GraphikBold.otf') format('OpenType');
}
@font-face {
    font-family: 'Kintanya';
    src: url('../assets/fonts/Kintanya.ttf') format('TrueType');
}
@font-face {
    font-family: 'Valorant';
    src: url('../assets/fonts/Valorant.ttf') format('TrueType');
}
@font-face {
    font-family: 'Sketch';
    src: url('../assets/fonts/KGSecondChancesSketch.ttf') format('TrueType');
}
@font-face {
    font-family: 'College Halo';
    src: url('../assets/fonts/College Halo.ttf') format('TrueType');
}
@font-face {
    font-family: 'Antically';
    src: url('../assets/fonts/AnticallyRegular-OVox8.ttf') format('TrueType');
}
@font-face {
    font-family: 'Balloon';
    src: url('../assets/fonts/Comication.TTF') format('TrueType');
}
@font-face {
    font-family: 'Handwriting';
    src: url('../assets/fonts/My_handwriting.ttf') format('TrueType');
}
@font-face {
    font-family: 'autumn';
    src: url('../assets/fonts/AutumnLeaf.otf') format('TrueType');
}