@import '../../partials/global';

.contact {
    height: 88vw;
    background-color: $background;

    &__heading {
        background-size: 75%;
        background-position: 50% 30%;
        background-clip: text;
        margin: 0;
        color: white;
        text-shadow: -0.03vw -0.03vw 0 #000, 0.03vw -0.03vw 0 #000, -0.03vw 0.03vw 0 #000, 0.03vw 0.03vw 0 #000;
    }
    &__button {
        background-repeat: no-repeat;
        background-size: 100% 85%;
        background-position: center;
        filter: grayscale(40%);
        opacity: 0.9;
        color: black;
        width: 19.25vw;
        height: 16.5vw;
        margin: 0.25vw 0;
        z-index: 998;

        &1 {
            background-image: url('../../assets/images/linkedin2.png');

            &:hover {
                background-image: url('../../assets/images/linkedin2-invert.png');
            }
        }
        &2 {
            background-image: url('../../assets/images/email2.jpg');

            &:hover {
                background-image: url('../../assets/images/email2-invert.jpg');
            }
        }
        &3 {
            background-image: url('../../assets/images/github2.jpg');

            &:hover {
                background-image: url('../../assets/images/github2-invert.jpg');
            }
        }
        &4 {
            background-image: url('../../assets/images/job search.png');

            &:hover {
                background-image: url('../../assets/images/job search-invert.png');
            }
        }
        &5 {
            background-image: url('../../assets/images/social2.png');

            &:hover {
                background-image: url('../../assets/images/social2-invert.png');
            }
        }
        &6 {
            background-image: url('../../assets/images/gaming.jpg');

            &:hover {
                background-image: url('../../assets/images/gaming-invert.jpg');
            }
        }

        &:hover {
            opacity: 1.2;
            cursor: pointer;
            filter: grayscale(50%);
        }

        &-logo {
            width: 3vw;
            height: 3vw;
            opacity: 0;
            margin-top: 0.25vw;
        }
        &-text {
            font-size: 2.5vw;
            margin-top: 11.75vw;
            font-family: 'Kintanya';
            padding: 0 2vw;
            width: 80%;
            text-align: center;
            font-weight: normal;
            z-index: 999;
            display: flex;
            justify-content: space-around;
            align-items: center;
        }

        &-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            margin: 0 12vw;
            padding-top: 10vw;
            position: relative;
        }

        &-link {
            display: flex;
        }
    }

    &__image {
        width: 20vw;
        position: absolute;
        top: 1;
        margin-top: 35vw;
        left: 35%;
        transform: scaleX(-1);
    }
    &__container {
        display: flex;
        position: absolute;
        align-items: center;
        right: 7vw;
        top: 1;
        margin-top: 39.5vw;
    }
    &__arrow {
        width: 10vw;
        padding: 0 20vw;
    }
    &__section {
        &-box {
            opacity: 0.3;
            background-image: url('../../assets/images/appa2.gif'); 
            background-position: 50% 70%; 
        }    
    }
    
}

.show {
    display: inline-block;
    z-index: 999;
}

.hide {
    display: none;
}