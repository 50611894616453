@import '../../partials/global';

.welcome {
    height: 40vw;
    color: white;
    background-color: $background;
    margin: 0;

    &__image {
        width: 35%;
        margin-top: 56.5vw;
        z-index: 998;
    }

    &__section {
        display: flex;
        width: 100%;

        &-text {
            display: flex;
            flex-wrap: wrap;
            width: 30%;
            text-align: left;
            position: relative;
            top: 10vw;
            left: 20%;
            z-index: 997;
            color: rgb(82, 81, 81);
            font-weight: bold;
        }
        &-polaroid {
            position: absolute;

            &1 {
                width: 15vw;
                right: 35vw;
                transform: rotate(20deg);
                margin-top: 5vw;
            }
            &2 {
                width: 25vw;
                right: 50vw;
                transform: rotate(-20deg);
                z-index: 999;
            }
            &3 {
                width: 15vw;
                right: -14.85vw;
                margin-top: -5vw;
                overflow: hidden;
            }
        }
        &-photo {
            position: absolute;

            &1 {
                width: 23vw;
                right: 51vw;
                transform:rotate(-20deg);
                margin-top: 1.5vw;
                z-index: 997;
            }
            &2 {
                width: 13.5vw;
                right: 35.5vw;
                margin-top: 6.25vw;
                transform:rotate(20deg);
            }
            &3 {
                width: 13.55vw;
                left: 31.05vw;
                margin-top: -3.25vw;
            }
        }
    }

    &__title {
        // background-image: url('../../assets/images/flower3.gif');
        // background-position: 0% 100%;
        // background-clip: text;
        // color: transparent;
        margin: 0;
        color: white;
        text-shadow: -0.03vw -0.03vw 0 #000, 0.03vw -0.03vw 0 #000, -0.03vw 0.03vw 0 #000, 0.03vw 0.03vw 0 #000;
    }
    &__maintext {
        margin-top: -41.5vw;

        &-first {
            color: $fadedorange;
            font-weight: bold;
            font-family: "GraphikMedium";
        }
        &-second {
            color: rgb(132, 160, 160);
            font-weight: bold;

            &:hover {
                cursor: pointer;
                color: $greenblue;
            }
        }
    }
}

.new {
    font-size: 10vw;
    position: absolute;
    font-family: 'autumn';
    background-image: url('../../assets/images/niagara.jpg');
    background-size: center;
    background-position: 30% 100%;
    // background-attachment: fixed;
    background-repeat: no-repeat;
    background-clip: text;
    color: transparent;
    // color: white;
    top:1;
    margin-top: 13vw;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    filter: saturate(15%) brightness(150%);
    z-index: 998;

    &2 {
        margin-top: 24vw;
        font-size: 10vw;
        left: 22vw;
    }
}

.big {
    font-size: 22vw;
    position: absolute;
    font-family: 'autumn';
    // background-image: url('../../assets/images/bouquet2.jpg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-clip: text;
    // color: transparent;
    color: white;
    top:1;
    left: 29.75vw;
    margin-top: 6vw;
    // filter: saturate(50%);
    // filter: saturate(50%) brightness(90%);
    z-index: 997;

    &2 {
        font-size: 15vw;
        left: 38vw;
        margin-top: 22vw;
    }
}
