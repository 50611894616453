@import '../../partials/global';

.headerbar {
    position: sticky;
    top: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 7.5vw;
    font-size: 3vw;
    display: flex;
    background-color: rgb(216, 221, 226);
    z-index: 999;
    font-family: 'autumn';
    border-bottom: 0.25vw solid white;

    &__logo {
        width: 17vw;
        margin-right: auto;

    }
    &__text {
        margin-right: auto;
        padding-right: 8.5vw;
    }
    &__menu {
        width: 8vw;
        height: 7.5vw;
        background-color: rgba($menublue, 0.9);
        margin: 0;
        color: white;
        font-size: 40px;

        &:hover {
            cursor: pointer;
            color: lightslategray;
        }

        &-logo {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding: 1.25vw;           

        }
        &-line {
            position: relative;
            font-size: 1.4vw;
            font-weight: bold;
            font-family: Arial, Helvetica, sans-serif;
        }
        &--hover {
            transition: 2s ease-in-out;

            &1 {
                animation: 0.5s line;
                animation-fill-mode: forwards;  
                visibility: visible;
            }
            &2 {
                animation: 0.5s line2;
                animation-fill-mode: forwards;  
            }
             
        }
        &--hide {
            visibility: hidden;
        }
        &--show {
            visibility: visible;
        }
    }
    &__image {
        // width: 50%;
        width: 33.3%;
        display: none;

        &-container {
            position: absolute;
            top: 1;
            margin-top: 10vw;
            display: flex;
            opacity: 0.8
        }
    }
}

.blur {
    opacity: 1;
}

.blob {
    position: absolute;
    z-index: 999;
    top: 0;
    margin-top: -2vw;
    left: 20vw;
    width: 55vw;
    opacity: 0.2;
}

.flower {
    width: 17%;
  height: auto;
}

