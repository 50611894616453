$mobile-breakpoint: 320px;
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1200px;

$menublue: #424952;
$headergreen: #dae2dd;
$orangered: #C65339;
$greenblue: rgb(149, 177, 192);
$fadedorange:rgb(156, 119, 105);
$background: #e8edf3;
// (167, 123, 105)