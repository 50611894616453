@import './variables';

@mixin mobile {
    @media screen and (min-width: $mobile-breakpoint) {
        @content;
    }
}
@mixin tablet {
    @media screen and (min-width: $tablet-breakpoint) {
        @content;
    }
}
@mixin desktop {
    @media screen and (min-width: $desktop-breakpoint) {
        @content;
    }
}

@keyframes line {
    to {
        transform: rotate(225deg) translate(-1.13vw, -1.2vw);
    }
}

@keyframes line2 {
    to {
        transform: rotate(-225deg) translate(-1.13vw, 1.1vw);
    }
}

@keyframes menuDrop {
    0% {
        height: 2px;
    }
    100% {
        height: 100%;
    }
}

@keyframes loadDots {
    0% {
        height: 5px
    }
    50% {
        height: 10px
    }
    100% {
        height: 15px
    }
}