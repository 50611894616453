@import '../../partials/global';

a {
    text-decoration: none;
    color: white;
    font-weight: bold;

    &:hover {
        color: $fadedorange;
    }
}

.navbar {
    position: sticky;
    top: 7.75vw;
    z-index: 999;
    width: 100%;
    height: 50px;
    background-color: rgba($menublue, 0.9);
    transform: scaleY(0);
    transform-origin: top;

    &__list {
        display: flex;
        justify-content: space-evenly;
        padding: 0.5vw 0;
        color: white;
        list-style-type: none;
        margin: 0;
    }
}

.fade-in {
    transform: scaleY(1);
    opacity: 1;
    transition:
      transform 0.5s ease,
      opacity 1s ease;
}
.fade-out {
    transform: scaleY(0);
    transform-origin: top;
    opacity: 0;
}

li {
    font-size: 1.4vw;
}