@import '../../partials/global';

.comments {
    height: 75vw;
    background-color: $background;

    &__heading {
        background-size: 30%;
        background-position: 5% 35%;   
        background-clip: text;
        margin-right: 30vw;
        padding-top: 10vw;
        margin-top: 0;   
        color: white;
        text-shadow: -0.03vw -0.03vw 0 #000, 0.03vw -0.03vw 0 #000, -0.03vw 0.03vw 0 #000, 0.03vw 0.03vw 0 #000; 
    }
    &__flowers {
        width: 35%;
        top: 1;
        margin-top: -39vw;
        position: absolute;
        right: 0;
        transform: scaleX(-1);
        z-index: 998;
    }
    &__section {

        &-box {
        background-image: url('../../assets/images/spiritedaway2.gif');
        background-position: 80% 55%;
        opacity: 0.3;
        }
    }

    &__box {
        display: flex;
        width: 100%;
        justify-content: center;

        
        &-comments {
            width: 30vw;
            position: absolute;
            top: 1;
            margin-top: -35.25vw;
            right: 9vw;
            height: 27vw;
            overflow: hidden;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                display: none;
            }
        }
        
        &-paper {
            width: 35vw;
            height: 35vw;
            margin: 4vw 5vw 0 0;
            filter: saturate(30%);
            position: relative;
        }
    }

    &__form {
        position: absolute;
        top: 1;
        margin-top: 2.5vw;
        left: 21.5vw;
        width: 21vw;
        z-index: 998;

        &-label {
            font-size: 0.5vw;
        }
        &-input {
            width: 21vw;
            font-family: 'Courier New', Courier, monospace;
            border: none;
            outline: none;

            &--comment {
                height: 10vw;
                resize: none;
                font-weight: bold;
            }
            &--name {
                height: 1vw;
                text-align: right;
                font-weight: bold;
            }
            &--position {
                height: 1vw;
                margin-top: 0.5vw;
                text-align: right;
                font-weight: bold;
            }
        }
        &-break {
            margin: 0;
        }
        &-button {
            border: none;
            font-family: 'Courier New', Courier, monospace;
            padding: 0.25vw 0.5vw;

            &:hover {
                cursor: pointer;
                background-color: rgb(204, 219, 224);
            }
        }
    }

    &__container {
        height: 23.25vw;
    }

    &__text {
        color: black;
        text-align: left;
        font-size: 0.805vw;
        padding: 0 2vw;
        font-family: 'Courier New', Courier, monospace;
        font-weight: bold;

        &-text {
            margin-bottom: 1vw;
            text-indent: 10%;
            line-height: 1.15vw;
        }
        &-name {
            text-align: right;
            font-weight: bold;
            margin: 0.25vw 0;
            font-size: 1.1vw;
            font-family: 'autumn';
        }
        &-position {
            text-align: right;
            font-weight: bold;
            margin: 0;
            font-size: 1.1vw;
            font-family: 'autumn';
        }
        &-count {
            position: absolute;
            top: 1;
            margin-top: 7vw;
            font-family: 'Courier New', Courier, monospace;
        }
    }

    &__logo {
        width: 1vw;
        position: absolute;
        top: 1;
        margin: 0.15vw 0 0 0.3vw;
        // filter: grayscale(1) invert(1);
    }
    &__typewriter {
        position: relative;
        width: 50vw;
        height: 43vw;
        margin-left: 5vw;
    }
}