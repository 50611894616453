@import './fonts';
@import './mixins';
@import './variables';

body {
    font-family: 'Graphik';
    // font-weight: bold;
}

h1 {
    font-size: 6vw;
}

h2 {
    font-size: 5vw;
}

p {
    font-size: 1.4vw;
}

.rainbow {
    background-image: linear-gradient(to left, rgb(241, 189, 241), rgb(182, 116, 230), rgb(138, 162, 238), rgb(157, 218, 157), rgb(243, 226, 128), rgb(241, 182, 70), rgb(247, 115, 115));
    background-clip: text;
    color: transparent;
    background-image: url('../assets/images/rainbow5a.gif');
    filter: grayscale(50%) brightness(150%);
}

.red {
    color: $orangered;
}
.green {
    color: $greenblue;
}
.highlight {
    color: rgb(165, 178, 182);
}
.bold {
    font-weight: bold;
    font-family:'GraphikMedium';
}
.flowers {
    opacity: 0.9;
    z-index: 997;
}
.box-title {
    color: white;
    font-weight: normal;
    border: 1px white solid;
    width: 25%;
    opacity: 0.5;
    position: absolute;   
    top: 1;
    padding: 2vw 0.5vw 0.5vw 0.5vw;
    margin-top: 11.5vw;
    left: 36vw;     
    font-family: 'Antically';   
    vertical-align: middle;  
    line-height: 5vw;
    border-radius: 0.25vw;
    font-size: 3vw;
    z-index: 997;
}
.title-box {
    border: 1px white solid;
    width: 25%;
    height: 6.5vw;
    position: absolute;   
    top: 1;
    margin-top: 12vw;
    left: 36.5vw;   
    border-radius: 0.25vw;
}

svg {
    display: none;
}

.parallax {
    background-attachment: fixed;
    background-size: 180% 100%;
    background-repeat: no-repeat;
    background-position: center;
    height: 30vw;
    border: 0.25vw solid white;
    border-left: none;
    border-right: none;

    &0 {
        background-image: url('../assets/images/images/toronto2_01.jpg');
        height: 50.75vw;
        border-top: none;
    }
    &1 {
        background-image: url('../assets/images/images/toronto2_02.jpg');
    }
    &2 {
        background-image: url('../assets/images/images/toronto2_03.jpg');
        margin-top: 42vw;
    }
    &3 {
        background-image: url('../assets/images/images/toronto2_04.jpg');
    }
    &4 {
        background-image: url('../assets/images/images/toronto2_05.jpg');
    }
    &5 {
        background-image: url('../assets/images/images/toronto2_06.jpg');
    }
}
